import dayjs from 'dayjs'
import jalaliday from 'jalaliday'
dayjs.extend(jalaliday)


export const getSimpleDayJS = (
  value: string,
  options: Record<string, string | boolean>
) =>
  dayjs(value, options)


export const getDayjs = (
  locale: 'en' | 'fa',
  value: string
) => {
  const day = dayjs(value)

  if (locale == 'fa')
    return day.calendar('jalali').locale('fa')
  else
    return day.calendar('gregory').locale('en')
}

export const convertNumToTime = (number: number) => {
  const sign = (number >= 0) ? 1 : -1
  number = number * sign

  const hour = Math.floor(number)
  let decpart = number - hour

  const min = 1 / 60
  decpart = min * Math.round(decpart / min)

  let minute = Math.floor(decpart * 60) + ''

  if (minute.length < 2)
    minute = '0' + minute

  const newSign = sign == 1 ? '' : '-'
  const time = sign + hour + ':' + minute

  return time
}
